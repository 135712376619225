<template>
<!-- 日程 -->
<div>
    <brandNewIndex v-if="ScheduleType==1"/>
    <newindex v-else-if="ScheduleType==2"/>
    <gridNewIndex v-else-if="ScheduleType==3"/>
    <!-- <gridNewIndex /> -->
</div>
</template>
<script>
import { mapState } from 'vuex'
import brandNewIndex from './brandNewIndex.vue';
import newindex from './newindex.vue';
import gridNewIndex from './gridNewIndex.vue';
export default {
    components:{
        brandNewIndex,
        newindex,
        gridNewIndex
    },
    data(){
        return{  
           Loading:true,
           params:null,
           ScheduleType:"",
        }
    },
    computed: {
        ...mapState(['themecolors', 'show_schedule'])
    },
    created(){
        this.params = this.$route.query;
        this.get_schedule_library_info_list()
    },

    methods:{
        get_schedule_library_info_list(){
            this.$store.dispatch("get_schedule_library_info_list",{
                page_id:this.params.site_id,
            }).then(res=>{
                if(res.data.code == 200) {
                    if(res.data.data.list){
                         this.ScheduleType=res.data.data.mode
                    }else{
                         this.ScheduleType="1"
                    }
                   this.Loading=false
                }
            })
         }
    },
}
</script>