<template>
  <div>
    <span v-if="error" class="error">加载错误</span>
    <div v-else class="root" :style="rootStyle">
      <div class="info_nav" @click="goBack" v-if="typer!=1">
        <i class="van-icon van-icon-arrow-left"></i>
        <p>{{ sessionTitle }}</p>
      </div>
      <!-- <sideBar /> -->
      <div class="head-img" v-if="headUrl">
        <img :src="headUrl" />
        <!-- <img src="./imgs/header.png" /> -->
      </div>
      <div class="main">
        <div v-if="showTitle" class="title" :style="titleStyle">
          {{ title }}
        </div>
        <div v-if="showTitle && showPlace" class="gap small"></div>
        <div
          v-if="showPlace"
          class="place"
          :style="placeStyle"
          v-html="place"
        ></div>
        <table class="table">
          <tr>
            <th>时间</th>
            <th>主题</th>
            <th v-if="showHost">主持</th>
            <th v-if="showGuest">讲者</th>
            <th v-if="showDiscuss">讨论嘉宾</th>
            <th v-if="showPresident">主席</th>
            <th v-if="showAddress">致辞</th>
          </tr>
          <!-- @click="viewDetail(item)" -->
          <tr v-for="item in list" :key="item.id" >
            <td style="white-space: nowrap" :style="tdStyle">
              {{ item.start_time_format }}-{{ item.end_time_format }}
            </td>
            <td :style="tdStyle">{{ item.agendum_title }}</td>
            <td v-if="showHost" class="ptd" :style="tdStyle">
              <span
                v-for="person in item.people_library[2] || []"
                :key="person.id"
                 @click.stop="personClick(person)"
              >
                {{ person.name }}
              </span>
            </td>
            <td v-if="showGuest" class="ptd" :style="tdStyle">
              <span
                v-for="person in item.people_library[1] || []"
                :key="person.id"
                @click.stop="personClick(person)"
              >
                {{ person.name }}
              </span>
            </td>
            <td v-if="showDiscuss" class="ptd" :style="tdStyle">
              <span
                v-for="person in item.people_library[3] || []"
                :key="person.id"
              >
                {{ person.name }}
              </span>
            </td>
            <td v-if="showPresident" class="ptd" :style="tdStyle">
              <span
                v-for="person in item.people_library[4] || []"
                :key="person.id"
                  @click.stop="personClick(person)"
              >
                {{ person.name }}
              </span>
            </td>
            <td v-if="showAddress" class="ptd" :style="tdStyle">
              <span
                v-for="person in item.people_library[5] || []"
                :key="person.id"
                  @click.stop="personClick(person)"
              >
                {{ person.name }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import EventBus from "../../js/bus"; // 引入vue事件总线
// import sideBar from '@/components/sidebar';
// 1:嘉宾
// 2:主持
// 3:讨论嘉宾
// 4:主席
// 5:致辞
export default {
  props: {
    typer: {
      type: Number,
      default: 0,
    },
    typerChange: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sessionTitle: "",
      error: false,
      list: [],
      belowUrl: "",
      headUrl: "",
      showTitle: true,
      showPlace: true,
      // 展示讲者
      showGuest: true,
      // 展示主持人
      showHost: true,
      // 展示讨论嘉宾
      showDiscuss: true,
      // 展示主席
      showPresident: true,
      // 展示致辞
      showAddress: true,
      place: "",
      // 会场
      title: "",
      titleBgColor: "",
      titleFontColor: "",
      placeBgColor: "",
      placeFontColor: "",
      tdFontColor: "",
    };
  },
  components: {
    // sideBar
  },
  computed: {
    rootStyle() {
      return this.belowUrl
        ? {
            backgroundImage: `url(${this.belowUrl})`,
          }
        : {};
    },
    titleStyle() {
      return {
        color: this.titleFontColor || "#000",
        backgroundColor: this.titleBgColor || "#fff",
      };
    },
    placeStyle() {
      return {
        color: this.placeFontColor || "#000",
        backgroundColor: this.placeBgColor || "#fff",
      };
    },
    tdStyle() {
      return {
        color: this.tdFontColor || "#000",
      };
    },
  },
  watch:{
    typerChange(val){
      setTimeout(() => {
      this.getData();
    }, 1000);
    }
  },
  mounted() {
    if(this.typer == 1){
      this.$nextTick(() => {
      this.getData();
    });
    }else{
      this.getData();
    }
    EventBus.$on('typechange',(value)=>{
      this.$nextTick(() => {
      this.getData();
    });
    })
  },
  methods: {
    getData() {
      const currentVenue = sessionStorage.getItem("scheduleCurrentItemConfig");
      const currentImage = sessionStorage.getItem("scheduleCurrentImage");
      if (currentVenue) {
        try {
          const { agendum_info, venue_title, venue_position, venue_time } =
            JSON.parse(currentVenue) || {};
          const {
            is_address,
            is_title,
            address_color,
            title_color,
            below_image_url = "",
            upper_image_url = "",
            guest_ids,
            address_ids,
            host_ids,
            discuss_ids,
            president_ids,
            address_typeface_color,
            title_typeface_color,
            form_typeface_color,
          } = JSON.parse(currentImage || "{}") || {};
          this.list = agendum_info || [];
          // 测试滚动
          // this.list.length = 20
          // const first = JSON.stringify(this.list[0])
          // for (let i = 1; i < this.list.length; i++) {
          //   this.list[i] = JSON.parse(first)
          // }

          this.titleFontColor = title_typeface_color;
          this.placeFontColor = address_typeface_color;
          this.tdFontColor = form_typeface_color;
          this.title = venue_title;
          this.sessionTitle = venue_title;
          const time = dayjs(venue_time * 1000).format("YYYY.M.DD");
          this.place = `${time}<span style="margin-left: 14px;">地点: ${venue_position}</span>`;
          this.showTitle = is_title == 1;
          this.showPlace = is_address == 1;
          this.showGuest = guest_ids == 1;
          this.showHost = host_ids == 1;
          this.showDiscuss = discuss_ids == 1;
          this.showPresident = president_ids == 1;
          this.showAddress = address_ids == 1;
          this.headUrl = upper_image_url;
          this.belowUrl = below_image_url;
          this.titleBgColor = title_color;
          this.placeBgColor = address_color;
          return;
        } catch (error) {
          console.error(error);
          this.$notify({
            type: "danger",
            message: "初始化信息失败！",
          });
        }
      }
      this.error = true;
    },
    goBack() {
      this.$router.back();
    },
    personClick(item) {
      this.params = {
        id: item.id,
      };
      window.sessionStorage.setItem("attendIntro", JSON.stringify(item));
      this.$cookies.set("people_id", item.id);
      this.$router.push("/attend_intro");
    },
    viewDetail(item) {
      let config = JSON.parse(
        window.sessionStorage.getItem("scheduleCurrentItemConfig")
      );
      window.sessionStorage.setItem(
        "scheduleTimelineCurrentItem",
        JSON.stringify(config)
      );
      // window.sessionStorage.setItem("scheduleTimeline",JSON.stringify(timeline))
      this.$router.push({
        name: "scheduleInfo",
        query: {
          id: item.venue_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$base: 100px;

@function rem($px) {
  @return $px / $base * 1rem;
}

.error {
  color: red;
  font-size: rem(20px);
}

.root {
  font-size: 0.12rem;
  overflow: auto !important;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  > .info_nav {
    flex: 0 0 auto;
    position: sticky;
    top: 0;
    padding: 0.12rem 0;
    color: #1d2129;
    font-size: 0.14rem;
    font-weight: 700;
    background-color: #fff;
    display: flex;
    align-items: center;

    ::v-deep > .van-icon {
      font-weight: 700;
      font-size: 0.18rem;
      margin-right: 0.08rem;
    }

    > p {
      width: calc(100% - 0.5rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > .head-img {
    flex: 0 0 auto;
    max-width: 100%;
    overflow: hidden;
    max-height: rem(400px);

    > img {
      width: 100%;
      max-height: 100%;
    }
  }

  > .main {
    flex: 1;
    padding: 20px 0.08rem;

    > .gap {
      &.small {
        height: 0.05rem;
      }

      &.large {
        height: 0.2rem;
      }
    }

    %bar {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      min-height: rem(20px);
      line-height: rem(20px);
    }

    > .title {
      @extend %bar;
      font-size: rem(14px);
    }

    > .place {
      @extend %bar;
      font-size: rem(12px);
      margin-bottom: 20px;
    }

    > .table {
      width: 100%;
      font-size: rem(9px);
      text-align: left;
      margin-top: rem(12px);
      border-collapse: collapse; /*合并边框*/

      th {
        white-space: nowrap;
        color: #1d2129;
        font-size: rem(10px);
        background-color: rgba(247, 248, 250, 1);
      }

      td {
        border-bottom: rem(1px) dashed #ccc;
        vertical-align: middle;

        &.ptd {
          word-break: keep-all;
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      th,
      td {
        padding: rem(4px);
      }

      /* 设置每列的占比 */
      th:nth-child(1),
      td:nth-child(1) {
        width: 16%;
      }

      th:nth-child(2),
      td:nth-child(2) {
        width: 24%;
      }

      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5),
      th:nth-child(6),
      td:nth-child(6),
      th:nth-child(7),
      td:nth-child(7) {
        width: 12%;
      }

      .persons {
        width: 100%;

        > .person {
          display: inline;
          word-break: keep-all;
          padding-right: 1em;
          max-width: 100%;
        }
      }
      .ptd span {
        display: block;
      }
    }
  }
}
</style>
