<template>
  <!-- 日程 -->
  <div
    class="root"
    :style="{ '--themecolor': themecolors }"
    v-if="show_schedule"
  >
    <div class="schedule" ref="schendulScroll">
      <div class="tabs" v-if="date_lineShow">
        <span class="tabs_left" v-if="date_line.length > 4">
          <van-icon name="arrow-left" :color="themecolors" />
        </span>
        <span class="tabs_arrow" v-if="date_line.length > 4">
          <van-icon name="arrow" :color="themecolors" />
        </span>
        <div class="swiper mySwiper" ref="myswiper" :key="date_line.length">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide tab-box"
              v-for="(i, j) in date_line"
              :key="j"
              :class="{ 'tab-active': i.id == schedule_library_id }"
              @click="active_meeting(i, j)"
            >
              <span class="week" v-if="i.type == 1">{{ i.week }}</span>
              <span
                class="tab-title"
                :style="i.type == 1 ? 'margin-top:0.02rem' : 'font-size:.13rem'"
                >{{ i.title }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="date_lineShow">
        <van-loading
          :color="themecolors"
          :text-color="themecolors"
          v-if="Loading"
        />
        <div class="null" v-else-if="schedule_place_config.length == 0">
          <div class="null-title">
            <p>暂无会场</p>
          </div>
          <van-empty :image="require('../../assets/attend/none-img.svg')" />
        </div>
        <div v-if="schedule_place_config.length == 1">
          <gridDetail :typer="typer" ></gridDetail>
        </div>
        <template>
          <div class="bottom_main" v-if="schedule_place_config.length != 1">
            <div
              class="session"
              v-for="item in schedule_place_config"
              :key="item.id"
              @click="viewDetail(item)"
            >
              <div class="session-img">
                <img
                  v-if="schedule_image"
                  alt="会场宣传图"
                  :src="schedule_image.cover_url"
                />
                <img
                  v-else
                  :src="require('../../assets/attend/none-img.svg')"
                />
              </div>
              <div class="session-info">
                <div class="session-title">
                  <span>{{ item.venue_title }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="iscover" class="cover_img">
          <div class="img_item">
            <img :src="coverurl" />
          </div>
          <div class="btns" @click="detailClick">进入详情</div>
        </div>
      </div>
      <div class="date_line_box" v-else>
        <van-loading
          :color="themecolors"
          :text-color="themecolors"
          v-if="Loading"
        />
        <div v-else>
          <van-empty :image="require('../../assets/attend/none-img.svg')" />
          <div class="date_line-title">
            <p>暂无日程</p>
          </div>
        </div>
      </div>
      <Support></Support>
    </div>
    <sideBar></sideBar>
  </div>
  <Newdetail v-else></Newdetail>
</template>
<script>
import Swiper from "swiper";
import { mapState } from "vuex";
import sideBar from "@/components/sidebar";
import Support from "@/components/support";
import Newdetail from "./newdetail.vue";
import gridDetail from "../../views/Schedule/gridDetail.vue";
import EventBus from "../../js/bus"; // 引入vue事件总线
export default {
  components: {
    sideBar,
    Support,
    Newdetail,
    gridDetail,
  },
  data() {
    return {
      typer: 0,
      displayindex: 0, //显示第几个日程
      ua: 0,
      scrollTop: 0,
      scrollleft: 0,
      value: "",
      unfold: false,
      Loading: false,
      params: {},
      meeting_place_id: null,
      schedule_library_id: null,
      schedule_place_config: [],
      schedule_image: null,
      date_line: [],
      date_lineShow: false,
      oldid: null,
      iscover: false,
      coverurl: "",
      arrow_up: false,
      showAttention: false,
      venue: [], //所以的会场
    };
  },
  created() {
    this.params = this.$route.query;
    this.get_schedule_library_info_list();
    if (
      sessionStorage.getItem("user_header") == 1 &&
      localStorage.getItem("wxmessage")
    ) {
      this.showAttention = true;
    } else {
      this.showAttention = false;
    }
  },
  watch: {
    schedule_place_config(val) {
      if (val.length == 1) {
        this.typer = 1;
        window.sessionStorage.setItem(
          "scheduleCurrentItemConfig",
          JSON.stringify(this.schedule_place_config[0])
        );
        window.sessionStorage.setItem(
          "scheduleCurrentImage",
          JSON.stringify(this.schedule_image || "")
        );
      }
    },
    show_schedule(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.schendulScroll.scrollTop = this.scrollTop;
          if (this.$refs.sessionScroll) {
            this.$refs.sessionScroll.scrollLeft = this.scrollleft;
          }
          new Swiper(".mySwiper", {
            slidesPerView: "4",
            spaceBetween: 12,
            freeMode: true,
            observer: true,
            observeParents: true,
          });
        });
      }
    },
    date_line(val) {
      if (val) {
        this.$nextTick(() => {
          new Swiper(".mySwiper", {
            slidesPerView: "4",
            spaceBetween: 12,
            freeMode: true,
            observer: true,
            observeParents: true,
          });
        });
      }
    },
  },
  computed: {
    ...mapState(["themecolors", "show_schedule"]),
  },
  mounted() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.ua = 1;
    }
  },
  methods: {
    // 监听滚动的位置
    showOut(e) {
      this.scrollTop = e.target.scrollTop;
    },
    showsession(e) {
      this.scrollleft = e.target.scrollLeft;
    },
    viewDetail(item) {
      window.sessionStorage.setItem(
        "scheduleCurrentItemConfig",
        JSON.stringify(item)
      );
      window.sessionStorage.setItem(
        "scheduleCurrentImage",
        JSON.stringify(this.schedule_image || "")
      );
      this.$router.push({
        name: "scheduleInfo3",
        query: {
          site_id: this.params.site_id,
          singePage: "singePage",
          backtrack: "backtrack",
          site_url:
            this.$route.query.site_url || sessionStorage.getItem("siteUrl"),
        },
      });
    },
    active_meeting(val, index) {
      if (val.id != this.prohibit) {
        // this.meeting_place_id = val.meeting_place_id,
        this.displayindex = index;
        this.schedule_library_id = val.id;
        this.schedule_place_config = this.date_line[index].scheduleList;
        if (this.schedule_place_config.length == 1) {
          EventBus.$emit('typechange', 1);
          window.sessionStorage.setItem(
            "scheduleCurrentItemConfig",
            JSON.stringify(this.schedule_place_config[0])
          );
          window.sessionStorage.setItem(
            "scheduleCurrentImage",
            JSON.stringify(this.schedule_image || "")
          );
        }
        this.schedule_image = this.date_line[index].schedule_image || [];
        // 不存在时，后台会返回一个空数组
        if (Array.isArray(this.schedule_image)) {
          this.schedule_image = null;
        }
        this.dragDistance = 0;
        this.prohibit = val.id;
      }
    },
    /** 转日期格式 */
    getLocalTime(time) {
      var now = new Date(time);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    // 转日期格式
    timestampToTime(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let date = new Date(time * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return MM + "-" + d;
    },
    timestampToweek(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let date = new Date(time * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d;
    },
    getWeek(dateString) {
      let dateArray = dateString.split("-");
      let date = new Date(
        dateArray[0],
        parseInt(dateArray[1] - 1),
        dateArray[2]
      );
      return "周" + "日一二三四五六".charAt(date.getDay());
    },
    /** 时间段范围 */
    isDuringDate(beginDateStr, endDateStr) {
      var curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    get_schedule_library_info_list() {
      this.Loading = true;
      this.$store
        .dispatch("get_schedule_library_info_list", {
          page_id: this.params.site_id,
          // module_id:this.params.module_id,
        })
        .then(async (res) => {
          if (res.data.code == 200) {
            document.title = res.data.data.module_title
              ? res.data.data.module_title
              : sessionStorage.getItem("wei-title") || "轻微站";
          }
          if (res.data.code == 200 && res.data.data.length != 0) {
            await this.get_schedule_venue_info_all_list();
            const todeday = new Date(new Date().toLocaleDateString()).getTime();
            const endday =
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1;
            this.displayindex = res.data.data.list.findIndex(
              (item) =>
                todeday <= Number(item.title) * 1000 &&
                Number(item.title) * 1000 <= endday
            );
            if (this.displayindex == -1) {
              this.displayindex = 0;
            }
            res.data.data.list.forEach((item) => {
              item.scheduleList = [];
              if (item.type == 1) {
                let week = this.timestampToweek(item.title);
                item.title = this.timestampToTime(item.title);
                item["week"] = this.getWeek(week);
              }
            });
            this.date_line = res.data.data.list;
            if (this.date_line.length > 0) {
              this.date_lineShow = true;
            }
            if (this.date_line[this.displayindex].cover_url) {
              this.iscover = true;
              this.coverurl = this.date_line[this.displayindex].cover_url;
            } else {
              this.iscover = false;
              // this.coverurl = this.date_line[this.displayindex].cover_url
            }
            // this.meeting_place_id = this.date_line[this.displayindex].meeting_place_id
            this.schedule_library_id = this.date_line[this.displayindex].id;
            this.schedule_place_config =
              this.date_line[this.displayindex].scheduleList;
            this.schedule_image =
              this.date_line[this.displayindex].schedule_image || [];
            // 不存在时，后台会返回一个空数组
            if (Array.isArray(this.schedule_image)) {
              this.schedule_image = null;
            }
            if (this.$refs.myswiper) {
              this.$refs.myswiper.swiper.slideTo(this.displayindex, 0, true);
            }
          } else {
            this.Loading = false;
          }
        });
    },
    // 获取议程信息
    get_schedule_agendum_info_all_list() {
      this.$store
        .dispatch("get_schedule_agendum_info_all_list", {
          page_id: this.params.site_id,
          // module_id:this.params.module_id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.venue.forEach((item1) => {
              res.data.data.forEach((item, index) => {
                item.forEach((i, ind) => {
                  i.live = this.isDuringDate(
                    i.start_time * 1000,
                    i.end_time * 1000
                  );
                });
                if (item[0]?.venue_id == item1.id) {
                  item1.agendum_info = item;
                }
              });
            });

            this.date_line.forEach((item) => {
              this.venue.forEach((item2) => {
                if (item.id == item2.library_id) {
                  item.scheduleList.push(item2);
                }
              });
            });
            this.Loading = false;
          }
        });
    },
    // 获取所有场地
    get_schedule_venue_info_all_list() {
      this.$store
        .dispatch("get_schedule_venue_info_all_list", {
          page_id: this.params.site_id,
          // module_id:this.params.module_id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            res.data.data.forEach((item) => {
              item.agendum_info = [];
            });
            this.venue = res.data.data;
            this.venue.forEach((item, index) => {
              if (item.status == 2) {
                this.venue.splice(index, 1);
              }
            });
            this.get_schedule_agendum_info_all_list();
          }
        });
    },
    unfoldClick(item) {
      this.schedule_place_config.forEach((i) => {
        i.id != item.id ? (i.unfold = false) : "";
      });
      item.unfold = !item.unfold;
    },
    scrollLeft() {
      this.scroll_left = $(".session-main").scrollLeft();
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.scrollLeft, true);
    },
    detailClick() {
      this.iscover = false;
    },
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
};
</script>
<style lang="less" scoped>
@Color: var(--themecolor);

/deep/.van-empty,
/deep/.van-loading {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translateX(-50%);
}

/deep/.van-empty {
  width: 100%;

  .van-empty__image {
    width: 3rem;
    height: 3rem;
  }
}

/deep/.van-loading {
  top: 50%;
}

.schedule {
  width: 100%;
  height: 100%;
  padding: 0.16rem 0.16rem 0;
  position: absolute;
  background: #fff;
  overflow: hidden;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;

  // .top {
  // 	flex: 0 0 auto;
  // 	padding: .14rem .16rem;
  // 	width: 100%;

  // 	/deep/.van-search {
  // 		padding: 0px;

  // 		.van-search__content--round {
  // 			border-radius: .3rem;
  // 			background-color: #F7F8FA;
  // 		}

  // 		.van-search__content {
  // 			.van-cell {
  // 				color: #8F9DAF;

  // 				.van-icon {
  // 					font-weight: bold;
  // 				}
  // 			}

  // 			.van-field__body {
  // 				.van-field__control {
  // 					color: #8F9DAF;
  // 					padding-left: .1rem;
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  .tabs {
    flex: 0 0 auto;

    &::-webkit-scrollbar {
      height: 0px;
    }

    .van-icon {
      font-weight: bold;
    }

    .tabs_left {
      left: 0.02rem;
      font-size: 0.18rem;
      position: absolute;
      top: 0.1rem;
      animation: tabsleft 1s linear infinite;
    }

    .tabs_arrow {
      right: 0.02rem;
      font-size: 0.18rem;
      position: absolute;
      top: 0.1rem;
      animation: tabsright 1s linear infinite;
    }

    @keyframes tabsleft {
      0% {
        left: 0.02rem;
      }

      50% {
        left: 0.08rem;
      }

      100% {
        left: 0.02rem;
      }
    }

    @keyframes tabsright {
      0% {
        right: 0.02rem;
      }

      50% {
        right: 0.08rem;
      }

      100% {
        right: 0.02rem;
      }
    }

    .tab-box {
      width: auto;
      height: 0.5rem;
      border-radius: 0.1rem;
      overflow: hidden;
      background: #f7f8fa;
      box-sizing: border-box;
      display: flex;
      padding: 0.06rem 0.11rem;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-left: 0.1rem;
      &:nth-child(1) {
        margin-left: 0;
      }

      .week {
        font-size: 0.12rem;
        color: #8f9daf;
      }

      .tab-title {
        font-size: 0.15rem;
        color: #1f5129;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // font-family: 'DinMedium';
        display: block;
        max-width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .tab-active {
      background: @Color;

      .week {
        color: #fff;
      }

      .tab-title {
        color: #fff;
        // font-family: 'DinBold';
        font-weight: 600;
      }
    }
  }

  .bottom {
    flex: 1 0 1px;
    margin-top: 0.16rem;

    .cover_img {
      position: absolute;
      top: 0;
      width: 100%;
      height: fit-content;
      background: #fff;

      .img_item {
        // min-height: calc(100vh - 0.3rem);
        // background-color: red;
        img {
          width: 100%;
        }
      }

      .btns {
        position: fixed;
        bottom: 0.25rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: rgba(0, 225, 175, 0.6);
        border-radius: 0.35rem;
        width: 1.8rem;
        height: 0.35rem;
        line-height: 0.35rem;
        text-align: center;
        color: #fff;
        font-size: 0.13rem;
      }
    }

    .null {
      width: 100%;
      height: auto;
      background: #fff;
      border-radius: 0.1rem;
      margin-bottom: 0.16rem;

      .null-title {
        padding: 0px 0.16rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.01rem #f5f6fa solid;
        height: 0.44rem;

        p {
          font-size: 0.18rem;
          width: 78%;
          // font-family: 'PingFangBold';
          font-weight: bold;
        }
      }

      /deep/.van-empty {
        position: static;
        transform: none;

        .van-empty__image {
          margin: 0.1rem 0;
        }
      }
    }

    .bottom_main {
      .session {
        margin-bottom: 0.1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        height: 0.7rem;

        > .session-img {
          display: flex;
          width: 1.24rem;
          height: 100%;
          border-radius: 0.1rem;
          overflow: hidden;
          flex-shrink: 0;

          > img {
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }

        > .session-info {
          flex: 1;
          font-size: 0.12rem;
          margin: 0 0.1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;
          > .session-title {
            width: 100%;
            word-break: break-all;
            font-weight: 700;
            font-size: 0.15rem;
            margin-top: 0.05rem;
            color: #000;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;

            .title-right {
              display: flex;
              align-items: center;

              span {
                font-size: 0.12rem;
                margin-right: 0.08rem;
                color: #8f9daf;
              }
            }
          }
        }

        .session-box {
          width: 100%;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: none;
          }

          .session-main {
            display: flex;
            margin-bottom: 0.1rem;

            &::after {
              content: "";
              display: block;
              padding-right: 0.1rem;
              height: 1.2rem;
            }

            .session-item {
              width: 1.2rem;
              height: 1.2rem;
              box-sizing: border-box;
              margin-left: 0.1rem;
              flex-shrink: 0;

              .pad-box {
                user-select: none;
                border-radius: 0.07rem;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border: 0.01rem solid #e1e3ee;
                // border-radius: 20px;
                box-shadow: 0px 0.02rem 0.06rem #e8ecf1;
                box-sizing: content-box;
              }

              .item-title {
                width: 100%;
                height: 0.33rem;
                display: flex;
                font-size: 0.12rem;
                justify-content: center;
                align-items: center;
                padding: 0 0.1rem;
                background: #f5fffe;
                color: #fff;
                border-bottom: solid 0.01rem #e1e2ec;

                .title-left {
                  display: flex;
                  align-items: center;

                  span {
                    display: flex;
                    font-size: 0.13rem;
                    // font-family: 'DinBold';
                    font-weight: bold;
                  }

                  .interval {
                    margin: 0px 0.02rem;
                  }
                }
              }

              .item-main {
                position: relative;
                padding: 0.1rem;

                .meeting-title {
                  height: 0.5rem;

                  p {
                    font-size: 0.14rem;
                    color: #505d6f;
                    // font-family: 'PingFangBold';
                    display: -webkit-box;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre-line;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                  }

                  .liveshow {
                    -webkit-line-clamp: 2;
                  }
                }

                .item_speaker {
                  font-size: 0.14rem;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  .speaker_name {
                    &::after {
                      content: "，";
                    }

                    &:last-child {
                      &::after {
                        content: "";
                      }
                    }
                  }
                }
              }
            }

            .live-conduct {
              border: 1px solid #ff5c60 !important;

              .item-title {
                background: #fff3f3;
                border-bottom: 0.01rem solid #ff5c60;
                // .title-left{
                //     span{
                //        color: #FF5C60;
                //     }
                // }
              }

              .item-main {
                .live-btn {
                  background: #fff2f2 !important;

                  // border: 1px solid #FF5C60;
                  span {
                    color: #ff5c60 !important;
                  }
                }
              }
            }
          }
        }

        .session-boxpc {
          width: 100%;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: block;
            height: 0.1rem;
            width: 100% !important;
          }

          &::-webkit-scrollbar-thumb {
            background: @Color;
            border-radius: 0.05rem;
          }
        }

        .session-main-two {
          padding: 0.25rem 0.16rem;

          .session-item {
            position: relative;

            .item-title {
              display: flex;
              align-items: center;

              .title-right {
                margin-left: 0.05rem;
                display: flex;
                align-items: center;
                padding: 0.05rem 0.14rem;
                background: #f0f2fa;
                border-radius: 0.1rem;

                span {
                  display: flex;
                  font-size: 0.12rem;
                  // font-family: 'DinBold';
                  font-weight: bold;
                }
              }
            }

            &::after {
              content: "";
              height: 86%;
              width: 0.01rem;
              background: #d5d7e4;
              position: absolute;
              top: 0.24rem;
              left: 0.05rem;
            }
          }

          .live-conduct {
            .item-title {
              .title-right {
                background: #fff3f1;

                span {
                  color: #ff5c60;
                }
              }
            }

            .item-main {
              .item-main-box {
                border: solid 0.01rem #ff5c60;
              }
            }

            &::after {
              background: #ff7174;
            }
          }
        }
      }
    }
  }

  .date_line_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    background: #fff;
    border-radius: 0.1rem;
    margin-bottom: 0.16rem;

    .date_line-title {
      position: absolute;
      bottom: 0.1rem;
      padding: 0px 0.16rem;
      width: 100%;

      // border-bottom: 0.01rem #F5F6FA solid;
      p {
        margin: 0 auto;
        text-align: center;
        font-size: 0.14rem;
        width: 78%;
        color: #999;
        // font-family: 'PingFangBold';
      }
    }

    /deep/.van-empty {
      position: static;
      transform: none;
      padding: 0;

      .van-empty__image {
        margin: 0.1rem 0;
      }
    }
  }
}

.live-gif {
  width: 0.24rem;
  height: auto;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
